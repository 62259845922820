import axios from 'axios'
import qs from 'qs'
import store from '@/store'
import {clearCookie, getCookie} from '@/utils/cookie'
import { ElNotification } from "element-plus";

// 创建 axios 实例
const service = axios.create({
    baseURL: window.config.DATA_URL,
    // baseURL: process.env.VUE_APP_API_BASE_URL,
    timeout:360000 // 请求超时时间6分钟
})

const err = (error) => {
    if (error.response) {
        const data = error.response.data
        const token = undefined
        if (error.response.status === 403) {
            console.log('request 403')
        }
        if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
            if (token) {
                store.dispatch('Logout').then(() => {
                    setTimeout(() => {
                        window.location.reload()
                    }, 1500)
                })
            }
        }
    }
    return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use(config => {
    // 参数签名处理
    if (config.method === 'get') {
        Object.assign(config.params ? config.params : {}, qs.stringify({...config.data}))
        // 如果是get请求，且params是数组类型如arr=[1,2]，则转换成arr=1&arr=2
        config.paramsSerializer = function(params) {
            return qs.stringify(params, { arrayFormat: 'repeat' })
        }
    } else if (config.method === 'delete') {
        config.headers.delete['Content-Type'] = 'application/x-www-form-urlencoded'
        config.data = qs.stringify({...config.data})
    }
    if (getCookie("AuthToken")) {
        config.headers['Authorization'] ='Bearer ' +  getCookie('AuthToken')
        config.headers['AuthToken'] = getCookie('AuthToken')
    }
    return config
}, err)

// response interceptor
service.interceptors.response.use((response) => {
        if (response.data.code === 200 || response.data.code === '0') {
            return Promise.resolve(response.data)
        } else if(response.data.code === 500 && (response.data.message === 'token无效或失效！' || response.data.message === '当前认证已失效！')){
            if (getCookie("AuthToken")) {
                clearCookie("AuthToken")
            }
            window.open(process.env.VUE_APP_API_BASE_URL + '/login', '_self')
            ElNotification({
                type: 'error',
                message: response.data.message,
            })
        }
        else {
            if (response.data && response.data.code) {
                if (response.data.code === 0) {
                    return Promise.resolve(response.data)
                } else {
                    return Promise.reject(response.data)
                }
            }
            if (response.headers['content-disposition']) {
                return response
            }
            if (response.status === 200) {
              return response
            }
            return Promise.reject(response.data)
        }
    }, (error) => {
    if (error.response.status === 501){
        if (getCookie("AuthToken")) {
            clearCookie("AuthToken")
        }
    }
        if (error.response && error.response.data) {
            const result = error.response.data
            return Promise.reject(result)
        }
        return Promise.reject(error)
    }
)

// 通用下载方法

export {
    service as axios
}
