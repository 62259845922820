<template>
  <div class='contain'>
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'BlankLayout'
}
</script>

<style scoped>
.contain {
  height: 100%;
}
</style>
