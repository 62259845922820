<!--
 * 侧边栏下方菜单
 * 递归子菜单，判断有无子组件，有的为submenu，且需要继续调用本组件->调用子组件，传入需要递归的数据;->没有子组件，直接渲染最后一级菜单
-->
<template>
  <el-sub-menu v-if="handleChildren(menu.children)" :index="setIndex(menu)">
    <template #title>
      <!--      <i :class="['iconfont',menu.icon]" class="icon"></i>-->
      <img :src="menu.meta.icon" style="width: 24px; height: 24px" />
      <span class="text-class">{{ menu.name }}</span>
    </template>
    <slide-menu
      v-for="child in menu.children"
      :key="child.path"
      :menu="child"
    ></slide-menu>
  </el-sub-menu>
  <el-menu-item v-else :index="setIndex(menu)" @click="clickMenu(menu)">
    <template #title>
      <!--      <i :class="['iconfont',menu.icon]"></i>-->
      <img :src="menu.meta.icon" style="width: 24px; height: 24px" />
      <span class="text-item-class">{{ menu.name }}</span>
    </template>
  </el-menu-item>
</template>

<script>
import { useRouter } from "vue-router";
import XEUtils from "xe-utils";

export default {
  props: ["menu"],
  setup() {
    const router = useRouter();
    const clickMenu = (menu) => {
      let name = menu.routerName ? menu.routerName : menu.path;
      // console.log(router.options.routes, 999)
      router.push({ name: name });
    };

    const setIndex = (menu) => {
      return menu.path;
    };

    const filerMenus = (menus) => {
      if (menus && menus.length > 0) {
        let _menus = XEUtils.toTreeArray(menus);
        return _menus.some((item) => item.type == 1);
      } else {
        return false;
      }
    };

    const handleChildren = (children) => {
      let result = false;
      if (children && children.length > 0) {
        children.forEach((item) => {
          if (item.type !== "view") {
            result = true;
          }
        });
      }
      return result;
    };

    return {
      router,
      setIndex,
      clickMenu,
      filerMenus,
      handleChildren,
    };
  },
};
</script>

<style lang="scss">
li.el-menu-item.is-active { // 选中子菜单颜色
  color: #3773F5  !important;
  background: #DBECFF;
  
}
.el-menu {// 子菜单颜色
  background: #f5f8fc;
}

.el-menu-item i,
.el-submenu i {
  margin-right: 8px;
  font-size: 18px;
  color: #fff;
}
.el-menu-item,
.el-submenu__title {
  height: 48px;
}
.el-menu-item,
.el-submenu__title {
  line-height: 40px;
}
.el-submenu .el-menu {
  background-color: rgb(39, 63, 143) !important;
  .el-menu-item {
    background-color: rgb(216, 201, 201) !important;
  }
}
.icon {
  margin-right: 10px;
}
.el-aside {
  height: 100vh;
}
.text-class {
  font-size: 16px;
  padding-left: 5px;
}
.text-item-class {
  font-size: 14px;
  padding-left: 5px;
}
</style>
