<!--
 * 基础布局，包含sass常用组件
-->
<template>
  <el-container :class="store.state.theme.style">
    <!-- 侧边栏 -->
    <el-aside>
      <side-bar></side-bar>
    </el-aside>
    <el-container style="background-color: #F5F6FF;">
      <el-header :height="navHeight" class="nav-header">
        <navigate-bar></navigate-bar>
      </el-header>
       <!-- 面包屑 -->
<!--      <div style="padding: 12px 24px 16px;z-index: 2;">-->
        <!-- <breadcrumb></breadcrumb> -->
<!--      </div>-->
      <!-- 主题内容 -->
      <el-main :style="styles" :class="mainClass">
        <el-scrollbar style="box-sizing:border-box;min-height: 100%;">
          <router-view :key="key" v-slot="{ Component }">
            <transition appear name="fade-transform" mode="out-in">
              <keep-alive>
                <component :is="Component" />
              </keep-alive>
            </transition>
          </router-view>
        </el-scrollbar>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
import {navHeight, navBarHeight} from "@/assets/style/variables.scss.js";
import NavigateBar from "@/components/base/NavigateBar.vue";
import SideBar from "@/components/base/SideBar.vue";
import {provide, reactive, toRefs, ref, watchEffect, computed, getCurrentInstance, onMounted, watch} from "vue";
import {useRoute} from "vue-router";
import Breadcrumb from "@/components/base/Breadcrumb";
import { useStore } from "vuex";

export default {
  name: "BasicLayout",
  components: {
    Breadcrumb,
    NavigateBar,
    SideBar
  },
  // 获取用户相关信息和路由权限
  setup() {
    const proxy = getCurrentInstance()
    const route = useRoute()
    const store = useStore()
    const key = computed(() => route.path)
    const styles = {"--navBarHeight": navBarHeight}
    const routerAlive = ref(null)
    const mainClass = ref('el-main')
    watchEffect(() => {
      routerAlive.value = route.meta.keepAlive;
      if (route.path === '/synthesisAnalysis/synthesisAnalysis/SynthesisAnalysis'){
        // 综合分析页面不需要padding
        mainClass.value = 'el-main-content'
      } else {
        mainClass.value = 'el-main'
      }
    })
    provide("reload", () => {
      routerAlive.value = Math.random() + "_" + Math.random()
    })
    onMounted(() => {
      proxy.userInfo = JSON.parse(localStorage.getItem('userInfo'))
      // methods.initWebSocket()
    })
    const data = reactive({
      socket: null,
      userInfo: null,
      // mainClass: 'el-main'
    })
    const methods = {
      initWebSocket() {
        const ip = window.location.host.split(':')
        const userId = proxy.userInfo.platformUser.accountName
        const orgId = proxy.userInfo.platformUser.orgId
        const tstr = userId + "," + ip[0].toString() + ',' + orgId
        proxy.socket = new WebSocket('ws://' + window.config.DATA_URL + '/monitor/websocket/' + tstr);
        // 建立 web socket 连接成功触发事件
        proxy.socket.onopen = () => {
          // 使用 send() 方法发送数据
          // proxy.socket.send('hh');
          // proxy.$message.warning('连接成功');
          console.log('连接成功');
        };
        // 接收服务端数据时触发事件
        proxy.socket.onmessage = (evt) => {
          // proxy.getSocketData(evt)
          console.log('数接接收', evt);
        };
        // 断开 web socket 连接成功触发事件
        proxy.socket.onclose = () => {
          // proxy.$message.warning('连接关闭');
          console.log('连接关闭');
        };
        // websocket错误
        proxy.socket.onerror = (err) => {
          console.log(err);
        }
      },
    }
    return {
      ...toRefs(data),
      ...toRefs(methods),
      mainClass,
      key, styles,store, navHeight, routerAlive,
    }
  }
};
</script>
<style lang="scss" scoped>

.el-header {
  box-shadow: 0 1px 4px #00152914;
  z-index: 19;height: 60px !important;
  background-color: #283D4A;
}

.el-main {
  height: calc(100vh - var(--navBarHeight));
  padding: 0 24px 24px;
  margin-top:28px;

  ::v-deep .el-scrollbar__bar.is-horizontal {
    visibility: hidden;
  }
}
.el-main-content {
  height: calc(100vh - var(--navBarHeight));
  padding: 0;
  margin-top:0;
  ::v-deep .el-scrollbar__bar.is-horizontal {
    visibility: hidden;
  }
}

::v-deep .el-aside {
  --el-aside-width: auto;
}
::v-deep .el-scrollbar__view {
  height: 100%;
  overflow-y: hidden;
}
</style>
