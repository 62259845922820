/**
 * 工具类
 */
 // import Vue from 'vue'
 import CryptoJS from 'crypto-js'
 export default {// 加密
   encrypt (word) {
     const keyStr = 'teruisa-AES-KEYS'
     var key = CryptoJS.enc.Utf8.parse(keyStr)// Latin1 w8m31+Yy/Nw6thPsMpO5fg==
     var srcs = CryptoJS.enc.Utf8.parse(word)
     var encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 })
     return encrypted.toString()
   }
   // 解密
   // decrypt (word, keyStr) {
   //   keyStr = keyStr || 'abcdefgabcdefg12'
   //   var key = CryptoJS.enc.Utf8.parse(keyStr)// Latin1 w8m31+Yy/Nw6thPsMpO5fg==
   //   var decrypt = CryptoJS.AES.decrypt(word, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 })
   //   return CryptoJS.enc.Utf8.stringify(decrypt).toString()
   // }
 }


// 防抖
 export function debounces(func, delay) {
  let timer = null;
  return function(){
      let context = this;
      let args = arguments;
      if(!timer){
          timer = setTimeout(function(){
              func.apply(context,args);
              timer = null;
          },delay)
      }
  }
}

// 导出
// export function exportXls(fun, params, name) {
//   fun(params).then(res => {
//     if (res.status === 200) {
//       const url = URL.createObjectURL(new Blob([res.data]))
//       const a = document.createElement('a')
//       document.body.appendChild(a) // 此处增加了将创建的添加到body当中
//       a.href = url
//       a.download = name + new Date().getTime() + '.xls'
//       a.target = '_blank'
//       a.click()
//       a.remove() // 将a标签移除
//     }
//   })
// }
