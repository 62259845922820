<!--
 * personal center
-->
<template>
  <div class="ve_personal">
    <!-- 换肤功能 -->
     <el-popover
      style="background: #ebebeb"
      placement="bottom"
      v-model:visible="visible"
      title="选择颜色"
      :width="200"
      trigger="click"
    >
      <template #reference>
        <a @click="visible = true" class="switch-style">
          <img src="~@/assets/platformIndex/application.png" />
          <span class="switch-text">切换颜色</span>
        </a>
      </template>
      <div>
        <div class="whiteClass" :class="[acitveName === 'white' ? 'active' : '']" @click="blackColorClick('white')">白色</div>
        <div class='blackClass' :class="[acitveName === 'black' ? 'active' : '']" @click="blackColorClick('black')">黑色</div>
      </div>
    </el-popover>
    <el-dropdown @command="handleCommand" style="cursor: pointer">
      <span class="ve_nav_dropdown">
        你好! {{ name }}<i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <template #dropdown>
        <el-dropdown-menu>
           <el-dropdown-item :command="{ name: 'personalCenterCommand' }" >个人中心</el-dropdown-item>
          <el-dropdown-item :command="{ name: 'logout' }">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script>
import variables from "@/assets/style/variables.scss.js";
import { useStore } from "vuex";
import { computed, ref, reactive, getCurrentInstance, toRefs, onMounted } from "vue";
import { clearCookie } from "@/utils/cookie";
import { useRouter } from "vue-router";
import {requestApi} from "../../utils/requestApi";
import {SVIPUpateUserApi} from "../../api/basicConfiguration";
import encryptUtil from "../../utils/encryptUtil";
import {getUserInfo} from "../../api/user";
export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const { proxy } = getCurrentInstance();

    const visible = ref(false);
    const dataVal = reactive({
      acitveName: 'white'
    })
    // const name = computed(() => store.getters.username)
    const name = computed(() => localStorage.getItem("accountNum"));
    const data = reactive({
      userInfo: {},
      userID:null,
      // 个人中心对话框
      personalCenterDialogVisible: false,
      // 个人中心form
      personalCenterForm:{
        password: ''
      },
      personalCenterRules:{
        'password': [
          {required: true, message: '请输入密码',trigger: ['blur', 'change'] },
          {max: 18, min: 8,message: '请输入8~18个字符',trigger: 'blur'}
        ],
        'username': [
          {required: true, message: '请输入姓名',trigger: ['blur', 'change']},
          {max: 18, min: 1,message: '最多输入18位字符',trigger: 'blur'}
        ],
        'phone': [
          {required: true, message: '请选择手机号',trigger: ['blur', 'change'] },
          {pattern:
                /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: '请输入正确的手机号',
            trigger: 'blur',
          }
        ],
      },
      // 用户等级信息
      levelInfo: {},
    })
    const personalCenterRef = ref()

    const personalCenterCommand = () => {
      // router.push({ path: "/personalCenter" });
      data.personalCenterDialogVisible = true
    };
    const logout = async () => {
      // let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      // if (userInfo.companyId) {
      //   await store.dispatch("user/EnterpriseLogout");
      // } else {
      //   await store.dispatch("user/Logout");
      //   await router.push({ path: "/login" });
      // }

      await store.dispatch("user/Logout");
      await router.push({ path: "/login" });


    };

    const handleCommand = (command) => {
      if (command.name === "personalCenterCommand") {
        personalCenterCommand();
      } else {
        logout();
      }
    };

    const methods = {
    //   // 个人中心对话框取消
    //   handlePersonalCenterCancel () {
    //     data.personalCenterDialogVisible = false
    //     data.personalCenterForm.password = ''
    //   },
    //   // 个人中心对话框确认
    //   handlePersonalCenterConfirm (formEl) {
    //     if (!formEl) return
    //     formEl.validate((valid, fields) => {
    //       if (valid) {
    //         const params = {
    //           username: data.personalCenterForm.username,
    //           password:  encryptUtil.encrypt(data.personalCenterForm.password),
    //           userId: data.personalCenterForm.userId,
    //           phone: data.personalCenterForm.phone,
    //         }
    //         requestApi(
    //           ()=> {
    //             return SVIPUpateUserApi(params)
    //           },
    //           (res) => {
    //             data.personalCenterDialogVisible = false
    //             proxy.$notify({
    //               title: '成功',
    //               message: res.message,
    //               type: 'success',
    //               duration: 1000
    //             })
    //             // methods.getUserInfo()
    //           }
    //         )
    //       } else {
    //         // proxy.$notify({
    //         //   title: '错误',
    //         //   message: '验证错误',
    //         //   type: 'error',
    //         //   duration: 1000
    //         // })
    //       }
    //     })
    //   },
      getUserInfo () {
        // 获取登录的用户信息
        requestApi(
          () => {
            return getUserInfo({
              accountName: data.personalCenterForm.accountName,
            });
          },
          (res) => {
            if (res.code === 200) {
              const obj = {
                platformUser: res.data.companyUser,
              };
              localStorage.setItem("userInfo", JSON.stringify(obj));
              data.personalCenterForm = {
                userId: res.data.companyUser.userId,
                accountName: res.data.companyUser.accountName,
                password:  '',
                username: res.data.companyUser.username,
                phone: res.data.companyUser.phone,
              }
            }
          }
        );
      }

    }

    // 颜色切换
    const blackColorClick = (data) => {
      proxy.acitveName = data
      store.commit("theme/SET_THEME", data);
      console.log(store.state.theme);
    };
    return {
      ...toRefs(dataVal),
      ...toRefs(data),
      ...toRefs(methods),
      name,
      visible,
      handleCommand,
      personalCenterCommand,
      blackColorClick,
      variables,
      store,
      personalCenterRef,
    };
  },
};
</script>

<style lang="scss" scoped>
.ve_personal {
  flex: 1;
  text-align: right;
  .ve_nav_dropdown {
    font-weight: bold;
  }
  .switch-style {
    margin-right: 20px;
  }
  .switch-text {
    padding: 0 10px 0 5px;
    font-size: 14px;
    color: #606266;
    font-weight: bold;
    cursor: pointer;
  }
  ::v-deep .el-popper.is-light {
    background: #ebebeb !important;
}
}
.whiteClass {
  margin: 0px 20px;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  float: left;
  background: #ffffff;
  border: 1px solid #f5f5f5;
  border-radius: 4px;
  cursor: pointer;
}
.blackClass {
  height: 50px;
  width: 50px;
  text-align: center;
  color: #ffffff;
  line-height: 50px;
  background: #000000;
  border: 1px solid #f5f5f5;
  border-radius: 4px;
  float: left;
  cursor: pointer;
}
.active {
  border: 1px solid #b8047c;
}
</style>
