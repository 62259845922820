<!--
 * sideBar
-->
<template>
  <router-link to="/">
    <!-- <logo /> -->
  </router-link>
    <div class="ve_el_menu" :style="styles">
      <el-scrollbar>
        <el-menu
          :style="{ width: widthSize + 'px' }"
          style="height: 100vh;overflow: auto"
          :default-active="defaultActive"
          :collapse="opened"
          :collapseTransition="false"
          unique-opened
          :active-text-color="red"
        >
          <div class="logo">
            <div
              v-if="!opened"
              style="display: flex; align-items: center; cursor: pointer">
              <img src="@/assets/icon/logo.png" />
              <span>智慧充电桩</span>
            </div>
            <el-icon v-if="!opened" @click="handleCilckIcon(true)" class="icon">
              <fold/>
            </el-icon>
            <el-icon v-else @click="handleCilckIcon(false)" class="icon">
              <expand/>
            </el-icon>
          </div>
          <slide-menu
            v-for="item in menus"
            :key="item.id"
            :menu="item"
          ></slide-menu>
        </el-menu>
      </el-scrollbar>
    </div>
</template>

<script>
import {
  navHeight,
  sideBgColor,
  sideTextColor,
  sideActiveTextColor,
} from "@/assets/style/variables.scss.js";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
// import Logo from "./Logo";
import SlideMenu from "./SlideMenu";

export default {
  components: { SlideMenu },
  setup() {
    const styles = { "--navHeight": navHeight };
    const route = useRoute();
    const router = useRouter();
    // const opened = computed(() => store.getters.opened);
    const opened = ref(false);
    const store = useStore();
    const menus = computed(() =>
      // store.getters.menus
      JSON.parse(localStorage.getItem("menus"))
    );

    const defaultActive = computed(() => {
      return route.name;
    });
    const widthSize = ref(260);
    const heightSize = ref(100)
    const handleCilckIcon = (key) => {
      if (key) {
        widthSize.value = 70;
      } else {
        widthSize.value = 260;
      }
      opened.value = key;
    };

    // 切换至应用管理
    const switchConsoleClick = async () => {
      // await router.push({ path: '/platformIndex' })
    };
    return {
      sideBgColor,
      sideTextColor,
      sideActiveTextColor,
      styles,
      opened,
      menus,
      defaultActive,
      navHeight,
      handleCilckIcon,
      widthSize,
      switchConsoleClick,
      store,
    };
  },
};
</script>

<style lang="scss" scoped>
.logo {
  height:100px;
  // background-color: #001529;
  display: flex;
  align-items: center;
  justify-content: space-around;
  & img {
    width:30px;
    height:30px;
    margin-right:5px
  }
  & span {
    //color:#000000;
    color: #BBBBBB;
    margin-left: 10px;
    font-size:20px
  }
}
.icon {
  //color: #39475B;
  font-size: 20px;
  cursor: pointer;
  margin-top: 1px;
}

.ve_el_menu {
    // background: #001529;
    height: 100%;
  & .el-menu{
    border-right: none;
  }
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  
}


//::v-deep .el-menu,
//::v-deep .el-scrollbar__view,
//::v-deep .el-sub-menu{
//  background: #384F5C;
//}
//::v-deep .el-sub-menu__title{
//  color: #BBBBBB // 菜单一级目录 字体颜色
//}
//
//::v-deep .el-sub-menu .el-menu {
//  // 子菜单背景颜色
//  border: none;
//  background: #384F5C;
//}
//::v-deep .el-menu-item { // 菜单二级级目录 字体颜色
//  color: #BBBBBB
//}
//::v-deep li.el-menu-item.is-active {
//  // 选中子菜单颜色
//  color: #FFFFFF !important;
//  background: #09A9BA;
//}
</style>
