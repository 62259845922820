// 按需引入elementUI矿建
// 引入组件
/* import {
    ElAvatar,
    ElBreadcrumbItem,
    ElDropdownItem,
    ElDropdown,
    ElDropdownMenu,
    ElBreadcrumb,
    ElTooltip,
    ElImage,
    ElContainer,
    ElHeader,
    ElAside,
    ElMain,
    ElTable,
    ElTableColumn,
    ElInfiniteScroll,
    ElLoading,
    ElMessage,
    ElMessageBox,
    ElNotification,
    ElButton,
    ElMenu,
    ElMenuItem,
    ElScrollbar,
    ElMenuItemGroup,
    ElSubMenu,
    ElIcon,
  } from 'element-plus'; */



  import { User,Unlock, Edit, Plus, DeleteFilled,Download, Search, ArrowUpBold, ArrowDownBold, ArrowLeftBold, ArrowRightBold, UploadFilled, Fold, Expand, CirclePlusFilled, RemoveFilled,CaretBottom,Switch, FolderAdd } from '@element-plus/icons'
  /* export const components = [
    ElAvatar,
    ElBreadcrumbItem,
    ElDropdownItem,
    ElDropdown,
    ElDropdownMenu,
    ElBreadcrumb,
    ElTooltip,
    ElImage,
    ElContainer,
    ElHeader,
    ElAside,
    ElMain,
    ElTable,
    ElTableColumn,
    ElButton,
    ElMenu,
    ElMenuItem,
    ElMenuItemGroup,
    ElSubMenu,
    ElIcon,
    ElScrollbar,
  ]
 */
 /*  export const plugins = [
    ElInfiniteScroll,
    ElLoading,
    ElMessage,
    ElMessageBox,
    ElNotification,
  ] */

  export const icons = [
    User,
    Unlock,
    Edit,
    Plus,
    DeleteFilled,
    Search,
    ArrowUpBold,
    ArrowDownBold,
    ArrowLeftBold,
    ArrowRightBold,
    UploadFilled,
    Fold,
    Expand,
    CirclePlusFilled,
    RemoveFilled,
    CaretBottom,
    Switch,
    FolderAdd,
    Download
  ]
