// import { get } from "core-js/core/dict";
import { axios} from "../utils/request";

const api = {
batch: window.config.DATA_URL+'file/v1/file/apply/upload/batch', //图标 批量上传
}

export default api

// 下载图标
export function getIconApi(params) {
  return axios({
    url: 'file/v1/file/apply/keys/' + params,
    method: 'get',
  })
}

// 组织管理
// 根据企业名称 查询企业
export function getSelectByCoName(params) {
  return axios({
    url: '/company/company/selectByCoName',
    method: 'get',
    params
  })
}
// 组织管理 导出
export function deriveXlse(params) {
  return axios({
    url: '/monitor/monitor/deriveXls',
    method: 'post',
    responseType: 'blob',
    params
  })
}
// 组织管理 下载模板
export function deriveXlsTwo(params) {
  return axios({
    url: '/monitor/monitor/deriveXlsTwo',
    responseType: 'blob',
    method: 'get',
    params
  })
}
// 组织管理 导入
export function addImportXls(data) {
  return axios({
    url: '/monitor/monitor/importXls',
    method: 'post',
    data
  })
}
// 查询组织导入是否成功
export function ImportXlss(data) {
  return axios({
    url: '/monitor/monitor/getStatus',
    method: 'post',
    data
  })
}
// 组织管理 导出重复数据
export function deriveReuse(params) {
  return axios({
    url: '/monitor/monitor/deriveReuse',
    method: 'get',
    responseType: 'blob'
  })
}
// 重置密码
export function updatePassword(data) {
  return axios({
    url: '/company/companyUser/updatePassword',
    method: 'post',
    data
  })
}
// 根据企业id 查询组织树
export function getCompanyOrgTree(params) {
  return axios({
    url: '/company/companyOrg/tree',
    method: 'get',
    params
  })
}
// 根据用户id查询组织树
export function getSelectUserOrg(data) {
  return axios({
    url: '/monitor/monitor/selectUserOrg',
    method: 'post',
    data
  })
}

// 更新用户绑定门店信息后的查询组织树的接口
export function getAllTree() {
  return axios({
    url: 'company/companyOrg/tree?companyId=37',
    method: 'get'
  })
}

// 根据用户id获取组织树
export function getByUserStore(data) {
  return axios({
    url: '/company/companyOrg/getByUserStore',
    method: 'post',
    data
  })
}


// 新增组织
export function addCompanyOrgTree(data) {
  return axios({
    url: '/company/companyOrg/addTo',
    method: 'post',
    data
  })
}

// 修改组织
export function editCompanyOrgTree(data) {
  return axios({
    url: '/company/companyOrg/editOrg',
    method: 'put',
    data
  })
}
// 根据企业id查询对应的组织id
export function orgSelectOneApi(params) {
  return axios({
    url: 'company/companyOrg/selectOne' +'?id='+params,
    method: 'get',
  })
}

// 删除组织
export function deleteCompanyOrgTree(data) {
  return axios({
    url: '/company/companyOrg/deleteTo',
    method: 'post',
    data
  })
}

// 添加门店
export function addMonitorInsertStore(data) {
  return axios({
    url: '/monitor/monitor/insertStore',
    method: 'post',
    data
  })
}

// 添加门店同步新增组织
export function addMonitorAddOrg(data) {
  return axios({
    url: '/monitor/monitor/addOrg',
    method: 'post',
    data
  })
}

// 组织管理的查询
export function getSelectAncestors(data) {
  return axios({
    url: '/monitor/monitor/selectAncestors',
    method: 'post',
    data
  })
}

export function getUserStore(data) {
  return axios({
    url: '/monitor/monitor/getUserStore',
    method: 'post',
    data
  })
}

// 根据组织ID及集团ID获取门店信息
export function getMonitorSelectByOrgIdOrComId(params) {
  return axios({
    url: '/monitor/monitor/selectByOrgIdOrComId',
    method: 'get',
    params
  })
}

// 根据主键修改门店信息
export function editMonitorUpdate(data) {
  return axios({
    url: '/monitor/monitor/update',
    method: 'post',
    data
  })
}

// 根据主键删除门店信息
export function romeMonitorDelete(data) {
  return axios({
    url: '/monitor/monitor/delete',
    method: 'post',
    data
  })
}

// 根据组织orgid查询门店
export function getOneStore(data) {
  return axios({
    url: '/monitor/monitor/getOneStore',
    method: 'post',
    data
  })
}


// 角色管理
// 根据应用查询菜单列表
export function getTreeSelectByappId(id) {
  return axios({
    url: '/apply/menu/treeSelectByappId/' + id,
    method: 'get',
  })
}
// 新增角色
export function monitorRoleAdd(data) {
  return axios({
    url: '/monitor/monitor/role/add',
    method: 'post',
    data
  })
}
// 删除角色
export function monitorRoleAddDelete(data) {
  return axios({
    url: '/monitor/monitor/role/delete',
    method: 'post',
    data
  })
}
// 修改角色
export function monitorRoleUpdate(data) {
  return axios({
    url: '/monitor/monitor/role/update',
    method: 'post',
    data
  })
}
// 查询角色
export function getByAncestors(data) {
  return axios({
    url: '/monitor/monitor/role/getByAncestors',
    method: 'post',
    data
  })
}
export function getAllByCompanyId(data) {
  return axios({
    url: '/company/companyRole/getAllByCompanyId',
    method: 'post',
    data
  })
}
// 根据角色id查菜单权限
export function companyRoleApplication(data) {
  return axios({
    url: '/company/companyRoleApplication/list',
    method: 'post',
    data
  })
}



// 用户管理
// 新增用户
export function addUserAdd(data) {
  return axios({
    url: '/monitor/effective/userAdd',
    method: 'post',
    data
  })
}
// 查询用户
export function getselectAncestors(data) {
  return axios({
    url: '/monitor/effective/selectAncestors',
    method: 'post',
    data
  })
}

// 做了绑定门店功能后的查询用户
export function getuserStore(data) {
  return axios({
    url: '/monitor/effective/getUserStore',
    method: 'post',
    data
  })
}

// 查询用户绑定的门店
export function getByUserId(data) {
  return axios({
    url: '/monitor/userStore/getByUserId',
    method: 'post',
    data
  })
}
// 删除用户
export function deleteEffective(data) {
  return axios({
    url: '/monitor/effective/delete',
    method: 'post',
    data
  })
}
// 删除用户
export function updateEffective(data) {
  return axios({
    url: '/monitor/effective/update',
    method: 'post',
    data
  })
}
// 用户管理导出
export function companyDeriveXls(params) {
  return axios({
    url: '/company/companyUser/deriveXls',
    method: 'get',
    responseType: 'blob',
    params
  })
}
// 管理员管理导出
export function adminImportXls(data) {
  return axios({
    url: '/company/companyUser/adminImportXls',
    method: 'post',
    responseType: 'blob',
    data
  })
}
// 用户下载模板
export function companyderiveXlsTwo(params) {
  return axios({
    url: '/company/companyUser/deriveXlsTwo',
    method: 'get',
    responseType: 'blob',
    params
  })
}
// 用户管理导入
export function companyimportXls(data) {
  return axios({
    url: '/company/companyUser/importXls',
    method: 'post',
    data
  })
}

// 用户管理导出错误信息
export function deriveresueXls() {
  return axios({
    url: '/company/companyUser/deriveresue',
    method: 'get',
    responseType: 'blob'
  })
}

// 用户账号去重
export function selectAccountName(data) {
  return axios({
    url: '/company/companyUser/selectAccountName',
    method: 'post',
    data
  })
}

// 用户手机号去重
export function selectPhone(data) {
  return axios({
    url: '/company/companyUser/selectPhone',
    method: 'post',
    data
  })
}


// 时间模板
// 查询所有时间模板
export function getAlleTime(data) {
  return axios({
    url: '/monitor/time/power/getAll',
    method: 'post',
    data
  })
}
// 根据模板名称查询时间格式等
export function getByNameTime(data) {
  return axios({
    url: '/monitor/time/power/getByName',
    method: 'post',
    data
  })
}
// 修改时间模板
export function updateByIdTime(data) {
  return axios({
    url: '/monitor/time/power/updateById',
    method: 'post',
    data
  })
}
// 删除时间模板
export function deleteByIdTime(params) {
  return axios({
    url: '/monitor/time/power/delete',
    method: 'get',
    params
  })
}
// 新增时间模板
export function addTimepower(data) {
  return axios({
    url: '/monitor/time/power/addTimepower',
    method: 'post',
    data
  })
}


// 设备资源管理
// 新增设备
export function addInsertStoreDevice(data) {
  return axios({
    // url: 'http://192.168.124.220:8040/monitor/wx/auth/insertStoreDevice',
    url: '/monitor/wx/auth/insertStoreDevice',
    method: 'post',
    data
  })
}

// 删除设备
export function delDeviceById(data) {
  return axios({
    // url: 'http://192.168.124.220:8040/monitor/monitorDevice/delDeviceById',
    url: '/monitor/monitorDevice/delDeviceById',
    method: 'post',
    data
  })
}

// 修改设备
export function updateDeviceById(data) {
  return axios({
    // url: 'http://192.168.124.220:8040/monitor/monitorDevice/updateDeviceById',
    url: '/monitor/monitorDevice/updateDeviceById',
    method: 'post',
    data
  })
}

// 通过组织id查询设备
export function getAllByOrg(data) {
  return axios({
    url: '/monitor/monitorDevice/getAllByOrg',
    method: 'post',
    data
  })
}

// 通过门店id查询设备
export function getAllMonitor(data) {
  return axios({
    // url: 'http://192.168.124.220:8040/monitor/monitorDevice/getAllMonitor',
    url: '/monitor/monitorDevice/getAllMonitor',
    method: 'post',
    data
  })
}
//
// 模糊查询
export function getQueryInfoByName(data) {
  return axios({
    // url: 'http://192.168.124.220:8040/monitor/monitorDevice/queryInfoByName',
    url: '/monitor/monitorDevice/queryInfoByName',
    method: 'post',
    data
  })
}
// 设备 导出
export function addDeriveXlsTow(params) {
  return axios({
    // url: 'http://192.168.124.220:8040/monitor/monitorDevice/deriveXlsTow',
    url: '/monitor/monitorDevice/deriveXlsTow',
    responseType: 'blob',
    method: 'get',
    params
  })
}

// 设备 下载模板
export function addDeriveXlsTemplate(params) {
  return axios({
    // url: 'http://192.168.124.220:8040/monitor/monitorDevice/deriveXlsTemplate',
    url: '/monitor/monitorDevice/deriveXlsTemplate',
    responseType: 'blob',
    method: 'get',
    params
  })
}
// 设备 导入
export function deviceImportXls(data) {
  return axios({
    // url: 'http://192.168.124.220:8040/monitor/monitorDevice/importXls',
    url: '/monitor/monitorDevice/importXls',
    method: 'post',
    data
  })
}

// 修改所在组织
export function updateByorg(data) {
  return axios({
    url: '/monitor/monitor/updateByorg',
    method: 'post',
    data
  })
}

// 查询nvr
export function getAllNVRByOrg(data) {
  return axios({
    url: '/monitor/monitorDevice/getAllNVRByOrg',
    method: 'post',
    data
  })
}

// 新增编辑时的组织树
export function selectUserOrg(data) {
  return axios({
    url: '/company/companyOrg/selectUserOrg',
    method: 'post',
    data
  })
}

// 新增编辑时的组织树
export function refreshApi(data) {
  return axios({
    url: '/company/company/refresh',
    method: 'post',
    data
  })
}

// 新增编辑时的组织树
export function SVIPUpateUserApi(data) {
  return axios({
    url: '/monitor/effective/SVIPUpateUser',
    method: 'post',
    data
  })
}

