import { getUserInfo, getUserMenu, login } from '@/api/user'
import { setCookie ,clearCookie } from '@/utils/cookie'
import { getSelectUserOrg, getByUserStore } from '@/api/basicConfiguration'
import {
  // getEntUserInfo,
  logoutAccount
} from "../../api/user";
import store from '@/store'
import router from "../../router";

const user = {
  namespaced: true,
  state: {
    token: '',
    username: '',
    name: '',
    avatar: '',
    authorities: [],
    info: {},
    menus: [],
    tree: [],
    companyId: '37'
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_USERNAME: (state, username) => {
      state.username = username
    },
    SET_ACCOUNT_NAME: (state, accountNum) => {
      state.accountNum = accountNum
    },
    SET_NICKNAME: (state, nickname) => {
      state.nickname = nickname
    },
    SET_NAME: (state, { name }) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_AUTHORITIES: (state, authorities) => {
      state.authorities = authorities
    },
    SET_INFO: (state, info) => {
      state.info = info
    },
    SET_MENUS: (state, menus) => {
      state.menus = menus
    },
    SET_TREE: (state, data) => {
      state.tree = data
    }
  },

  actions: {
    // 平台登录
    Login ({ commit }, loginInfo) {
      return new Promise((resolve,reject) => {
        login(loginInfo).then(res => {
          if (res) {
            const token = res.data.access_token
            commit('SET_TOKEN', token)
            setCookie("AuthToken", token)
            localStorage.setItem("userInfo", JSON.stringify(res.data.mercharnt))
          }
          resolve()
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // 获取用户信息
    GetInfo ({ commit }, accountNum) {
      // let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      // if (userInfo.companyId) {
        return new Promise((resolve, reject) => {
          getUserInfo({accountName: accountNum}).then(res => {
            const result = res.data.companyUser
            commit('SET_USERNAME', result.username)
            commit('SET_ACCOUNT_NAME', result.accountName)
            commit('SET_INFO', result)
            resolve()
          }).catch((e) => {
            reject(e)
          })
        })
      // }else {
      //   return new Promise((resolve, reject) => {
      //     getEntUserInfo({accountName: accountNum}).then(res => {
      //       const result = res.data.sysUser
      //       // commit('SET_AVATAR', result.avatar)
      //       // commit('SET_USERNAME', result.name)
      //       commit('SET_ACCOUNT_NAME', result.accountNum)
      //       commit('SET_INFO', result)
      //       resolve()
      //     }).catch((e) => {
      //       reject(e)
      //     })
      //   })
      // }

    },

    // 获取菜单信息
      async GetMenu({commit}) {
        // let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        try {
          let res = null
          // if (userInfo.companyId) {
          //   // 如果存在companyId => 获取企业的菜单
          //   //  res = await getEnterpriseMenu(obj)
          // } else {
          //    res = await getUserMenu()
          // }
          const userInfo = JSON.parse(localStorage.getItem("userInfo"));
          let level = userInfo.chargingMerchant.level
          let roleId = userInfo.chargingMerchant.roleId
          res = await getUserMenu({
            level: level,
            roleId: roleId
          })
          const permissionList = res.data

          let authorities = []

          commit('SET_AUTHORITIES', authorities)
          commit('SET_MENUS', permissionList)
          localStorage.setItem('menus',JSON.stringify(permissionList))
          console.log(permissionList,777)
          return permissionList
        } catch (e) {
          console.log(e, 'catch err')

          // if (userInfo.companyId) {
          //   store.dispatch('user/EnterpriseLogout')
          // } else {
          //   store.dispatch('user/Logout')
          // }
        }
        // console.log('我检查userInfo', JSON.parse(localStorage.getItem('userInfo')));
      },


    // 登出
    Logout ({ commit }) {
      console.log(commit, '退出')
      return new Promise((resolve,reject) => {
        logoutAccount().then(res => {
          if(res.code === 200) {
            console.log('logout success')
            commit('SET_TOKEN', '')
            commit('SET_AUTHORITIES', [])
            commit('SET_MENUS', '')
            clearCookie("AuthToken")
            localStorage.clear()
            sessionStorage.clear()
            router.options.routes = []
          }
          resolve()
        }).catch((e) => {
          reject(e, 'logout catch error')
        })
      })
    },
    // 查询组织树
    getTree({ commit }) {
      return new Promise((resolve,reject) => {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'))
        const formdata = new FormData()
        formdata.append('userId', userInfo.platformUser.userId)
        // getSelectUserOrg(formdata).then(res => {
          getByUserStore(formdata).then(res => {
          if(res.code === 200) {
            commit('SET_TREE', res.data)
            const result = JSON.stringify(res.data)
            localStorage.setItem('entrepreneurData', result)
          }
          resolve()
        }).catch((e) => {
          reject(e, 'error')
        })
      })
    }
  }
}

// function getAuthorities(permissionList,authorities,result) {
//   if(permissionList&&permissionList.length>0){
//     permissionList.forEach(element => {
//       if(element.type=='view'){
//         authorities.push(element.urlKey)
//         if(element.url&&element.url.length>0){
//           result.push(element)
//         }
//       }
//       if(element.children&&element.children.length>0){
//         let children = element.children
//         element.children = []
//         getAuthorities(children,authorities,element.children)
//       }
//       if(element.type=='menu'){
//         result.push(element)
//       }
//     });
//   }
// }

export default user
