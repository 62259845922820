export default [
    {
      path: "/404",
      name: "404",
      component: () => import("@/pages/exception/404.vue")
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/pages/Login.vue")
    },
    {
      path: "/forgetPassword",
      name: "forgetPassword",
      component: () => import("@/pages/forgetPassword/ForgetPassword.vue")
    },
    {
      path: '/:pathMatch(.*)',
      redirect: '/404'
    }
]
