<!--
 * 面包屑导航
-->
<template>
    <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item v-for="(item, index) in list" :key="index">{{
            item
        }}</el-breadcrumb-item>
    </el-breadcrumb>
</template>

<script>
import { treeFindPath } from "@/utils";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { computed } from "vue";
export default {
    setup() {
        const store = useStore();
        const route = useRoute();
        const menuList = computed(() => store.getters.menus).value;
        const list = computed(() => {
          let name = route.name;
          const listBar = treeFindPath(menuList,data => data.path == name)
          return listBar;
        });

        return {
            list
        };
    }
};
</script>

<style lang="scss" scoped>
.el-breadcrumb{
    user-select:none;
}
</style>
