// import {getCurrentInstance} from "vue";
import {ElNotification} from 'element-plus';

// 封装请求
export async function requestApi (req, func, isCodeFalse = false,func1) {
    try {
        const res = await req()
        if (res.code === 200 || res.data.code === 200 || res.status===200) {
            func(res)
        } else {
            // 如果res.code ！== 200，需要传isCodeFalse = true，和func1
            if (isCodeFalse === true) {
                func1(res)
            } else {
                ElNotification({
                    title: '错误',
                    type: 'error',
                    message: res.message,
                })
            }
        }
    } catch (e) {
        console.log(e)
        ElNotification({
            title: '错误',
            type: 'error',
            message: e.message,
        })
    }
}
