/**
 * 在路由守卫中，只有next()是放行，其他的诸如：next('/login') 、 next(to) 或者 next({ ...to, replace: true })都不是放行，而是：中断当前导航，执行新的导航
 */
import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import mainRoutes from "./router.main"
import globalRoutes from "./router.global"
import { getCookie } from '@/utils/cookie'
import store from '@/store'

const whiteList = ['login', 'register','forgetPassword']

const router = createRouter({
    history: createWebHistory(),
    scrollBehavior: () => ({ y: 0 }),
    isAddDynamicMenuRoutes: false,
    routes: globalRoutes.concat(mainRoutes)

//     history: createWebHashHistory(),
//     routes: globalRoutes.concat(mainRoutes)
});

router.beforeEach((to, from, next) => {
    console.log('in router beforeEach...')
    const hasToken = getCookie("AuthToken")
    if (hasToken) {
        if (to.path === '/login') {
            next({ path: '/' })
        } else {
            // 判断是否已经缓存了菜单信息
            // const menus = store.getters.menus
            const menus = JSON.parse(localStorage.getItem('menus'))
            // console.log(menus,'菜单信息')
            if (menus && menus.length >0) {
                console.log('already has menus.. ,pass')
                router.options.routes = router.options.routes ? router.options.routes : []
                if (router.options.routes.length === 5 && to.name === '404') {
                  next({ path: to.redirectedFrom.fullPath })
                  router.options.routes = JSON.parse(sessionStorage.getItem('menuItem'))
                }
                if (from.fullPath === '/login' && to.fullPath === "/index"  ||
                from.fullPath === '/' && to.fullPath === "/index" ||
                from.fullPath === '/404' && to.fullPath === "/index") {
                  const str = menus[0].children[0].component
                  next({ path: str })
                }
                let urlKey = ''
                routerPackag(menus,"index",1,urlKey)
                next()
            } else {
                try {
                    // const accountNum = localStorage.getItem('accountNum')
                    // store.dispatch('user/GetInfo',accountNum)
                    console.log('start get menus..')
                    store.dispatch('user/GetMenu').then((res) => {
                        const menuList = res
                        console.log('初始化menuList', menuList);
                        if (menuList) {
                            menuList.forEach((element) => {
                                element.children.forEach(item => {
                                    // 将route信息放入router.options.routes
                                    // console.log(item);
                                    router.options.routes.push(item)
                                })
                            })
                            sessionStorage.setItem('menuItem', JSON.stringify(router.options.routes))

                            let urlKey = ''
                            routerPackag(menuList,"index",1,urlKey)
                            // urlKey = menuList[0].path.replace("/","");
                            // if(!(urlKey&&urlKey.length>0)){
                            //     if(menuList[0].children&&menuList[0].children.length>0){
                            //         urlKey = menuList[0].children[0].path
                            //     }
                            // }
                            // 去指定的路由,如需求指定了默认进入哪个页面
                            const str = menuList[0].children[0].component
                            console.log('start go menu:%s ...',urlKey)
                            next({ path: str })
                            // next({ name: urlKey })
                            // 不指定，缓存了路由信息以后重新进入
                            // next({ ...to, replace: true })
                        } else{
                            console.log('no menus..., reTry...')
                            next({ ...to, replace: true })
                        }
                    })

                } catch (error) {
                    // next(`/404`)
                }
            }
        }
    } else {
        // 没有token应该重定向到登录页面
        if (whiteList.includes(to.name)) {
            next()
        } else {
            next({ path: '/login' })
        }
    }
    })

const routerPackag = (routers,parentName,level) => {
    routers.forEach(item => {
        const component = startWith(item.component, "/") ? item.component : "/" + item.component
        let routerItem = {
            path: `${item.component}`,
            name: item.path,
            component: () => import(`../pages${component}`),
            meta: {title: item.name},
            children: []
        }
        if (item.children && item.children.length > 0) {
            routerPackag(item.children, parentName,level+1);
        }
        router.addRoute(parentName, routerItem);
        // console.log(router.getRoutes(), 888)
    })
}

function startWith(str, prefix){
    const reg = new RegExp('^' + prefix)
    return reg.test(str)
}

router.afterEach(() => {})

export default router;
