import { BasicLayout} from '@/layouts'
export default [
{
  path: "/",
  name: 'index',
  type: 1,
  component: BasicLayout,
  redirect: {name: "Home"},
  children: [
    {
      path: "home",
      name: "Home",
      redirect: {name: 'Menu1'}
    },
    {
      path: 'index',
      name: 'Menu1',
      component: () => import('@/pages/Index'),
      meta: {title: 'Menu1', keepAlive: true, permission: ['form']}
    },
    {
      path: 'testScreen',
      name: 'testScreen',
      component: () => import('@/pages/synthesisAnalysis/synthesisAnalysis/testScreen'),
      meta: {title: 'Menu1', keepAlive: true, permission: ['form']}
    },
  ]
},
]
