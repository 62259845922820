import { axios } from '@/utils/request'

const api = {
  token: 'charging/merchant/login',// 登录
  user: 'company/companyUser/info',// 获取用户信息
  // menu: 'apply/menu/getRouters/', // 获取用户菜单
  menu: 'apply/menu/getRouters/', // 充电桩获取用户菜单

  enterpriseMenu: 'apply/menu/getRouters/',  // 获取企业菜单
  getCode: window.config.DATA_URL + 'platform/user/code',//获取验证码图片
  checkCode: 'platform/user/code/check',//效验验证码

  logoutAccount: 'charging/merchant/logout',// 登出
}

/**
 * 获取用户信息
 */
export const getUserInfo = (parameter) => {
  return axios({
    url: api.user,
    method: 'get',
    params: parameter
  })
}

/**
 * 验证验证码
 */
export const checkCode = (params) => {
  return axios({
    url: api.checkCode,
    method: 'get',
    params: params
  })
}

/**
 * 登录
 */
export const login = (parameter) => {
  return axios({
    url: api.token,
    method: 'post',
    data: parameter
  })
}

/**
 * 退出
 */
export const logoutAccount = (parameter) => {
  return axios({
    url: api.logoutAccount,
    method: 'post',
    data: parameter
  })
}

/**
 * 获取登录用户菜单权限
 */
export const getUserMenu = (parameter) => {
  return axios({
    url: api.menu,
    method: 'get',
    params: parameter
  })
}

// 获取企业菜单
export const getEnterpriseMenu = (parameter) => {
  return axios({
    url: api.enterpriseMenu,
    method: 'get',
    params: parameter
  })
}

//企业登录获取登录用户的信息
export const getEntUserInfo = (parameter) => {
  return axios({
    url: api.userEntInfo,
    method: 'get',
    params: parameter
  })
}

// 查询用户等级
export function selectOneUserId(data) {
  return axios({
    url: '/company/companyRole/selectOneUserId',
    method: 'post',
    params: data
  })
}

export default api
