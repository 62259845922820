const getters = {
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  username: state => state.user.username,
  nickname: state => state.user.name,
  welcome: state => state.user.welcome,
  authorities: state => state.user.authoritises,
  menus: state => state.user.menus,
  menuList: state => state.app.menuList,
  opened: state => state.app.slider.opened,
  userInfo: state => state.user.info,
  permissionRoutes: state => state.permission.routes,
  theme: state => state.theme
}

export default getters
