import { createStore } from 'vuex'
import app from './modules/app'
import permission from './modules/permission'
import user from './modules/user'
import theme from './modules/theme'
import getters from './getters'

export default createStore({
  modules: {
    app,
    user,
    permission,
    theme
  },
  state: {

  },
  mutations: {

  },
  actions: {

  },
  getters
})
 