import { createApp } from 'vue'
import 'element-plus/dist/index.css'
import locale from './language/zh-cn'
import router from './router'
import store from './store'
import App from './App.vue'
import ElementPlus from 'element-plus'
// import 'element-plus/dist/index.css'
import less from 'less'
import {icons} from './common/element'
import './assets/iconfont/iconfont.css'
import '@/assets/global.less'
import '@/assets/style/index.scss'
import './utils/date.js'
import './utils/jquery2.0'
import echarts from './utils/echarts';
import './utils/rem'
// import scss from 'scss'
// import '@/assets/style/variables.scss'




const app = createApp(App)
//按需使用elemntUI组件
/* components.forEach(component => {
    app.component(component.name, component)
}) */
/* plugins.forEach(plugin => {
    app.use(plugin)
}) */
icons.forEach( icon => {
    app.component(icon.name, icon)
})

app.config.globalProperties.$echarts = echarts

// { locale }
app.use(ElementPlus, { locale }).use(router).use(store)
app.use(less)
// app.use(scss)
app.mount('#app')
