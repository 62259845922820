<template>
    <router-view />
</template>

<script setup>
import * as echarts from "echarts";
import { provide } from "vue";
provide("echarts", echarts);
// export default {
//   name: "app",
//   created() {}
// };
</script>

<style lang="scss">
@import "./assets/style/variables.scss";
html,
body{
  min-width: 1170px;
}
* {
  margin: 0;
  padding: 0;
}

// 特殊节日全局变灰
// body *{
//   -webkit-filter: grayscale(100%);
//   -moz-filter: grayscale(100%);
//   -ms-filter: grayscale(100%);
//   -o-filter: grayscale(100%);
//   filter: grayscale(100%);
//   filter:progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
//   filter:gray;
// }
</style>
