<!--
 * top bar
-->
<template>
  <div class="ve_nav_bar">
    <div class="nav_title">智慧充电平台系统</div>
    <div class="nav_avatar">
      <personal></personal>
      <!-- <el-avatar :size="35" :src="avatar"></el-avatar> -->
      <img class="touClass" src="@/assets/login/touxiang1.png" />
    </div>
  </div>
</template>
<script>
import Personal from "./Personal.vue";
import { reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  name: "NavigateBar",
  components: {
    Personal,
  },
  setup() {
    const state = reactive({});
    const avatar = computed(() => useStore().getters.avatar);
    return {
      ...toRefs(state),
      avatar,
    };
  },
};
</script>
<style lang="scss" scoped>
.ve_nav_bar {
  display: flex;
  align-items: center;
  height: 100%;
  .nav_title {
    font-size: 25px;
    font-weight: 400;
    line-height: 44px;
    //color: #000000;
    color: #BBBBBB;
    opacity: 1;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .nav_avatar {
    display: flex;
    align-items: center;
  }
  .touClass {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    border: 1px solid #d8d4d4;
  }
}
</style>
