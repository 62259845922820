export default {
  namespaced: true,
  state: {
    // Color:{
    //   sideBgColor: "#111111",// mens 菜单背景色
    //   sideTextColor: "#38465A", // mens 菜单字体颜色
    // },
    style:'black'// 默认为白色
  },
  mutations: {
    SET_THEME(state, data) {
      // const arr = Object.keys(data)
      // state[arr[0]] = data[arr[0]];
      state.style=data
      // console.log(state.style,'state.style')
    },
  },
  actions: {
    ThEME({ commit }, data) {
      commit("SET_THEME", data);
    }
  }
};
