let variables = {
    'main-bg-color': "#f5f5f5",
    'base-color': "#409EFF",
    navHeight: "80px", // 导航头高度
    navBarHeight: "92px",
    mainColor: "#d9ecff",// 全局颜色
    'side-close-width': "65px",
    'side-open-width': "160px",
    mainBackground: "#F5F7FB",
    sideBgColor: "#293E67",// mens 菜单背景色
    sideTextColor: "#fff", // mens 菜单字体颜色
    sideActiveTextColor: "red",
};




module.exports = variables;
