/**
* @description 通过树节点id获取子父节点路径的函数
* @param {*} tree
* @param {*} func
* @param {*} path
* @return {*}
*/
export const treeFindPath = (tree, func, path = []) => {
    if (!tree) return []
     for (const data of tree) {
     path.push(data.name)
     if (func(data)) return path
     if (data.children) {
     const findChildren = treeFindPath(data.children, func, path)
    if (findChildren.length) return findChildren
    }
     path.pop()
    }
     return []
    }
    